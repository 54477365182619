
export default {
  name: 'AtomImageSliderMatterportSpace',
  props: {
    sliderImage: {
      required: true,
      type: Object,
      default: () => {
        return {
          image: ''
        }
      }
    }
  },
  data() {
    return {
      isInView: false,
      imageLoaded: false
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters.cdnBase}/${url}`
    },
    handleLazyLoad() {
      this.isInView = true
      const imageElement = new Image()
      if (imageElement) {
        imageElement.addEventListener('load', () => {
          this.imageLoaded = true
        })
        imageElement.addEventListener('error', () => console.log('error'))
        imageElement.src = this.resourceUrl(this.sliderImage.photo.url)
      }
    }
  }
}
