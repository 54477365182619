
export default {
  name: 'AtomImageSlider',
  props: {
    sliderImage: {
      required: true,
      type: Object,
      default: () => {
        return {
          image: '',
          thumb: ''
        }
      }
    },
    backgroundSize: {
      required: false,
      type: String,
      default: 'cover'
    },
    backgroundColor: {
      required: false,
      type: String,
      default: "#000",
    },
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters['base/cdnBase']}/${url}`
    }
  }
}
