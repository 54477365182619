
export default {
  name: 'AtomButton',
  props: {
    label: {
      required: false,
      type: String,
      default: 'Submit'
    },
    icon: {
      required: false,
      type: String,
      default: ''
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    onClick: {
      required: false,
      type: Function,
      default: () => {
        // console.log("default action");
      }
    }
  }
}
